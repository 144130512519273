import {
    ApolloClient,
    InMemoryCache,
    // split,
    HttpLink,
} from "@apollo/client";
// import 'isomorphic-fetch'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

// https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/#handling-client-only-routes-with-gatsby
// const wsLink = process.browser ? new WebSocketLink({
//     uri: 'ws://localhost:4000/subs',
//     options: {
//         reconnect: true,
//         connectionParams: {
//             token: 'chicken'
//         }
//     }
// }) : null;

const httpLink = new HttpLink({
    uri: 'http://localhost:4000/graphql'
})

// const splitLink = process.browser ? split(
//     ({ query }) => {
//         console.log(query);
//         const definition = getMainDefinition(query);
//         return definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//     },
//     wsLink,
//     httpLink
// ) : httpLink;

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
})

export default client;