import React, { useState, useEffect } from 'react'
import Layout from '../layout'
import styled from 'styled-components'
import { useMutation, gql } from '@apollo/client'
import { navigate } from 'gatsby'

const Styled = styled.div`
    max-width: 600px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    margin-top: 20px;
    input {
        border-radius: 5px;
        border: 1px solid lightgrey;
        height: 32px;
        width: 300px;
        margin: 10px;
        padding-left: 15px;
    }
    button {
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        display: block;
        margin-top: 15px;
    }
`


const LOG_IN = gql`
mutation Login($username: String!, $secret: String!) {
  login(username: $username, secret: $secret) {
    user {
      id
      username
    }
    token
  }
}
`

const Login = () => {
    const [username, setUsername] = useState('')
    const [secret, setSecret] = useState('')

    const [login, { data, loading, error }] = useMutation(LOG_IN, {
        onCompleted: (result) => {
            localStorage.setItem('accessToken', result?.login?.token);
            console.log(result);
            navigate('/admin');
        }
    })

    useEffect(() => {
        localStorage.setItem('accessToken', data?.login?.token)
    }, [data])

    return (
        <Layout>
            <Styled>
                <form onSubmit={(ev) => {
                    login({ variables: { username, secret } })
                    ev.preventDefault();
                }}>
                    <div>
                        <input
                            onChange={ev => setUsername(ev.target.value)}
                            placeholder="Username"
                            value={username}
                        />
                    </div>
                    <div>
                        <input
                            onChange={ev => setSecret(ev.target.value)}
                            value={secret}
                            type="password"
                            placeholder="Secret"
                        />
                    </div>
                    <button
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : `Log In`}
                    </button>
                    <div className="message">
                        {error?.message}
                    </div>
                </form>
            </Styled>
        </Layout>
    )
}

export default Login
