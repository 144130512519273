import React from 'react'
import Layout from '../layout'
import { useQuery } from '@apollo/client'
import ImportForm from '../ImportForm'
// import ProgressPill from '../ProgressPill'
import { GET_HISTORY, IMPORT_STATUS } from '../../queries'

// const ImportStatusBar = () => {
//   const { data, loading, error } = useSubscription(IMPORT_STATUS, { variables: { tld: 'n/a' } });
//   if (loading) return 'Loading...'
//   if (error) return error.message;

//   const { message, percentComplete } = data.tldImportEvent;

//   return (
//     <ProgressPill
//       currentValue={percentComplete}
//       label={message}
//     />
//   )
// }

const ImportHistory = () => {
  const { data, error, loading } = useQuery(GET_HISTORY)


  if (error) return error.message;
  if (loading) return 'Loading...';

  const { importHistory } = data;
  return (<table>
    <thead >
      <tr>
        <th>TLD</th>
        <th>Last Import</th>
        <th>Qty</th>
        <th>Duration</th>
        <th>Re-Import</th>
      </tr>
    </thead>
    <tbody>
      {importHistory.map((el, i) => (
        <tr key={i}>
          <td>{el.tld}</td>
          <td>{el.updatedAt}</td>
          <td>{el.uniqueDomains}</td>
          <td>{el.duration}</td>
          <td>button</td>
        </tr>
      ))}
    </tbody>
  </table>)
}


const Dashboard = () => {
  return (
    <Layout>
      <div>
        <ImportForm />
      </div>
      {/* <ImportStatusBar /> */}
      <div className="m-8">
        <ImportHistory />
      </div>
    </Layout>
  )
}

export default Dashboard
