import React from 'react'
import { useQuery, gql } from '@apollo/client'

const GET_TLD_LIST = gql`
  query GetTldList {
    getTldList {
      tld
    }
  }
`


const TldListDropdown = (props) => {
  const { data, loading, error } = useQuery(GET_TLD_LIST);

  if (error) return error.message;
  if (loading) return 'Loading...'

  return (
    <select {...props}>
      {data.getTldList.map(({ tld }) => (<option key={tld} value={tld}>{tld}</option>))}
    </select>
  )
}

export default TldListDropdown
