import { gql } from '@apollo/client'

export const GET_HISTORY = gql`
query ImportHistory {
importHistory {
  uniqueDomains
  tld
  updatedAt
  duration
}
}
`

export const IMPORT_STATUS = gql`
subscription TldImportEvent($tld: String!) {
tldImportEvent(tld: $tld) {
  message
  percentComplete
}
}
`

export const IMPORT_TLD = gql`
mutation ImportTld($tld: String!) {
  importTld(tld: $tld) {
    percentComplete
    message
  }
}
`