import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import TldList from './TldList'
import { IMPORT_TLD, GET_HISTORY } from '../queries'


const ImportForm = () => {
  const [importTld] = useMutation(IMPORT_TLD, { refetchQueries: GET_HISTORY })
  const [tld, setTld] = useState();

  const handleSubmit = ev => {
    importTld({ variables: { tld: tld || 'com' } })
    ev.preventDefault();
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TldList
          onChange={ev => setTld(ev.target.value)}
        />
        <button>import</button>
      </form>
      <div>
        Status:
      </div>
    </>
  )
}

export default ImportForm
