import React from 'react'
import { Router } from "@reach/router"
import Dashboard from '../../components/Dashboard'
import Login from '../../components/Login'
import PrivateRoute from '../../components/PrivateRoute'
import { ApolloProvider } from "@apollo/client";
import client from '../../helpers/apollo-client'

const Admin = () => {
    return (
        <ApolloProvider client={client}>
            <Router basepath="/admin">
                <Login path="/login" />
                <PrivateRoute path="/" component={Dashboard} />
            </Router>
        </ApolloProvider>
    )
}

export default Admin
