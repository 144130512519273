import React from "react"
import { navigate } from "gatsby"
// import { isLoggedIn } from "../services/auth"

const hasToken = () => localStorage.getItem('accessToken');

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!hasToken() && location.pathname !== `/admin/login`) {
    navigate("/admin/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute